/* starting ENTER animation */
.timestamp-enter {
    color: #8CC57D;
    /*font-weight: 500;*/
    transform: translateY(-2%) scale(1.05);
}

/* ending ENTER animation */
.timestamp-enter-active {
    transform: translateY(0%) scale(1);
    color: #9CA3AF;
    /*font-weight: 200;*/
    transition: all 1000ms ease-in-out;
}

/* starting EXIT animation */
.timestamp-exit {
    opacity: 0;
}

/* ending EXIT animation */
.timestamp-exit-active {
    opacity: 0;
    transition: all 100ms;
}
